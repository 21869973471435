import { NgModule } from '@angular/core';
import { EyesSharedModule } from 'prosumer-app/libs/eyes-shared';
import { ErrorPageComponent } from './error-page.component';
import { ErrorRoutingModule } from './error-routing.module';

@NgModule({
  imports: [EyesSharedModule.forFeature(), ErrorRoutingModule],
  declarations: [ErrorPageComponent],
})
export class ErrorPageModule {}
