<prosumer-page pageTitle="Page Not Found">
  <p>
    {{ 'Generic.messages.errorPage' | translate }}
  </p>
  <p>
    {{ 'Generic.messages.resourceNotFound' | translate }}
  </p>
  <p>
    {{ 'Generic.messages.forFurtherHelp' | translate }}
    <a class="eyes-href-bold" routerLink="/support" target="_blank">{{
      'Generic.messages.support' | translate
    }}</a>
  </p>
</prosumer-page>
